.Mobile {
  display: none;
  align-items: center;
}

.containerb {
  max-width: 375px;
  margin: 0 auto;
  padding: 0 20px;
}
#transition-modal-description {
  display: none;
}

#transition-modal-description a {
  text-align: center;
  color: rgb(70, 69, 69);
  margin-left: 10px;
  text-decoration: none;
  line-height: 45px;
}

#transition-modal-description ul {
  margin: 0;
  padding: 0;
}

#transition-modal-description li {
  list-style: none;
  /* padding-left: 0px; */
  text-align: center;
}
@media (max-width: 950px) {
  .Header {
    display: none;
  }
  #transition-modal-description {
    display: block;
    margin-top: 200px;
  }
  .swiper-slide {
    width: 100%;
    object-fit: cover;
    height: 250px;
  }

  .Mobile {
    display: flex;
    align-items: center;
    position: relative;
  }

  .Nav .na img {
    margin-right: 10px;
  }
  .Nav {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #22b3c1;
  }

  .Nav .na {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
  }

  .Nav img {
    width: 150px;
    height: 60px;
    /* object-fit: cover; */
    /* border-radius: 50%; */
  }
  .container {
    margin: 0 auto;
    padding: 0 20;
    width: 100%;
  }

  .Nav button {
    float: right;
    font-size: 25px;
    position: relative;
  }
  .mySwiper1 {
    height: 100%;
  }
  .main-intro h1 {
    font-size: 26px;
  }
  .mySwiper2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .mySwiper2 .img-decorate {
    padding: 0;
  }

  .mySwiper2 .weekly-tour {
    position: absolute;
    width: 200px;
    background-color: #fff;
    border-radius: 23px;
    right: 20px;
    top: 50%;
    transform: translateY(-5%);

    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }
  .Visa-main .weekly-tour {
    position: absolute;
    background-color: #fff;
    border-radius: 23px;
    right: 20px;
    top: 60%;
    transform: translateY(-15%);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    padding: 30px;
    width: 200px;
  }
  .Section h1 {
    font-size: 25px;
    text-align: center;
  }
  .section-p {
    text-align: center;
  }
  .section-cards-items {
    display: block;
    gap: 0px;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
  }
  .section-cards-items img {
    width: 100% !important;
    object-fit: cover;
  }
  .section-text {
    margin: 0px;
  }
  .section-v {
    text-align: center;
  }
  .new-section-item {
    gap: 0px;
  }
  .new-section-item p {
    margin-right: 10px;
  }
  .containerr {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  .contact-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .contact-text h1 {
    font-size: 30px;
  }
  .contact-text p {
    font-size: 20px;
  }
  .connect {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row-connect {
    display: flex;
    justify-content: center;
  }
  .cold input {
    width: 100%;
    padding: 0 !important;
  }
  .cold select {
    width: 100%;
  }
  .content-main {
    display: block;
  }
  .content-button {
    width: 100%;
    margin-top: 10px;
  }
  .content-button button {
    width: 100%;
  }

  .cold {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-row {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
  }
  .footer-row h2 {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
  .col-lg-8 {
    width: 100%;
  }
  .col-lg-8 h4 {
    text-align: center;
  }
  .border-button {
    width: 100%;

    justify-content: center;
  }
  .foot-end {
    display: none;
  }
  .Footer {
    padding: 25px 0px;
  }
  .carddl {
    display: none;
  }
  .row-reservation {
    padding-left: 10px;
    padding-right: 10px;
  }
  .menu-langg {
    display: flex;
    justify-content: center;
  }
  .language {
    border: none;
    /* background: none; */
    color: black;
    text-align: center;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}

.mobile-gallery-image {
  display: none;
}

@media (max-width: 550px) {
  .mobile-gallery {
    display: none;
  }
  .mobile-gallery-image {
    display: block;
  }
  .mobile-gallery-list {
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(2, minmax(100%, 250px)); */
  }
  .gallery-image {
    width: 50%;
    object-fit: cover;
    height: 250px;
  }
  .cold-1 textarea {
    width: 88%;
  }
}
