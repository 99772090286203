.about {
  padding: 30px 10px;
}

.about-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-image {
  height: 550px;
  object-fit: cover;
}

.about-title {
  font-size: 35px;
  line-height: 40px;
  margin-top: 30px;
  text-align: center;
}

.about-text {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: black;
  width: 1000px;
  text-align: center;
  margin: 15px auto;
}

@media (max-width: 768px) {
  .about-text {
    width: 100%;
    font-size: 16px;
  }
  .about-title {
    font-size: 22px;
    line-height: 30px;
  }
}
