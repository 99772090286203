.language {
  color: black !important;
  border: none;
  outline: none;
}

.content-link {
  background-color: #22b3c1;
  color: #fff;
  padding: 12px 30px;
  border-radius: 25px;
  border: 1px solid #22b3c1;
}

.footer-bottom-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  gap: 15px;
}

.footer-bottom-list a {
  font-size: 18px;
  line-height: 26px;
}

.header-link svg {
  width: 180px !important;
  object-fit: cover;
}

.footer-list {
  display: flex;
  align-items: center;
  gap: 20px;
  list-style-type: none;
  margin-top: 20px;
}

.static-number {
  font-family: Unbounded !important;
}

.feedback-form {
  padding: 15px 10px;
}

.form label {
  font-size: 16px;
  line-height: 24px;
  color: #110d60;
  font-weight: 500;
  margin: 8px 0;
}

.contact-input {
  padding: 12px 15px;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  border-radius: 12px;
  font-weight: 500;
  margin: 5px 0;
  background-color: rgb(245, 251, 253);
  resize: none;
  border: 1.5px solid rgb(245, 251, 253);
}

.list-style {
  list-style-type: circle;
}

.contact-input:focus {
  border: 2px solid #110d60;
}

.contact-input::placeholder {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #110d60;
}
.form {
  display: flex;
  flex-direction: column;
}

.form label {
  display: flex;
  flex-direction: column;
}

.zomin-list {
  display: flex;
  gap: 15px;
  margin: 15px 0;
}

.zomin-list img {
  width: 280px;
}

@media (max-width: 768px) {
  .header-link {
    width: 200px !important;
  }
  .footer-list {
    justify-content: center;
    display: flex;
  }
  .footer-bottom-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .zomin-list img {
    width: 48%;
  }
}
